var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-select",
    {
      staticStyle: { width: "100%" },
      attrs: {
        value: _vm.value,
        placeholder: _vm.$t("lbl_choose"),
        "allow-clear": "",
        disabled: _vm.disabled,
        "dropdown-match-select-width": false
      },
      on: { change: _vm.onChange }
    },
    _vm._l(_vm.dtOpt, function(item) {
      return _c(
        "a-select-option",
        {
          key: item.key,
          attrs: { value: item.value },
          on: {
            click: function() {
              return _vm.onSelect(item.value, item)
            }
          }
        },
        [
          _c(
            "a-tooltip",
            [
              _c("template", { slot: "title" }, [
                _vm._v(" " + _vm._s(item.key || "-") + " ")
              ]),
              _vm._v(" " + _vm._s(item.key || "-") + " ")
            ],
            2
          )
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }